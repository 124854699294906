//float clear
@mixin clearfix {
    display: block;
    clear: both;
    content: "";
}

//rem
@function rem($font-size) {
    @return $font-size/$default-font-size#{rem};
    // @return $font-size;
}

// flexbox
@mixin flex($align, $justify) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: $align;
    -ms-flex-align: $align;
    align-items: $align;
    -webkit-box-pack: $justify;
    -ms-flex-pack: $justify;
    justify-content: $justify;
}

@mixin flexbox() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin align-items($value) {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
    align-items: $value;
}

@mixin justify-content($value) {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
    justify-content: $value;
}

// reset button
@mixin resetButton() {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

// vertical center
@mixin vertical-center {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

// horizontal center
@mixin horizontal-center {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

// all center
@mixin position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

// vender prefix
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}

// clamp
@mixin line-clamp($lineHeight, $height, $line) {
    line-height: $lineHeight;
    max-height: $height;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

// device size
$desktop-width: 1050px;
@mixin desktop-width {
    @media screen and (min-width: #{$desktop-width}) {
        @content;
    }
}