.accordion {
    $root: &;
    padding-left: 10px;
    position: relative;
    &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% - 80px);
        //height: 100%;
        left: 10px;
        top: 0;
        background-color: #f5f7fa;
    }
    &__item {
        padding-left: 10px;
        &+& {
            margin-top: 20px;
        }
    }
    &__anchor {
        display: block;
        position: relative;
        padding: 8px 12px;
        background-color: #f5f7fa;
        border-radius: 0 4px 4px 4px;
        margin-left: 12px;
        color: #333;
        &::before {
            position: absolute;
            content: '';
            border-style: solid;
            border-width: 0 12px 12px 0;
            border-color: transparent #f5f7fa;
            display: block;
            width: 0;
            left: -12px;
            top: 0px;
        }
        &::after {
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            top: -3px;
            left: -25px;
            border-radius: 100%;
            border: 2px solid #0ba8ff;
        }
        /* 
        &:last-of-type {
            &.is-active {
                #{$root} {
                    &::before {
                        height: calc(100% - 60px);
                    }
                }
            }
        } */
    }
    &__wrap {
        margin-top: 20px;
    }
    &__box {
        display: block;
        position: relative;
        padding: 8px 12px;
        background-color: #e9fffe;
        border-radius: 0 4px 4px 4px;
        margin-left: 12px;
        &::before {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 0 12px 12px 0;
            border-color: transparent #e9fffe;
            display: block;
            width: 0;
            left: -12px;
            top: 0px;
        }
        &::after {
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            top: 0;
            left: -25px;
            border-radius: 100%;
            border: 2px solid #04d6cd;
        }
        &--call {
            background-color: #ffefef;
            &::before {
                border-color: transparent #ffefef;
            }
            &::after {
                position: absolute;
                content: '';
                width: 7px;
                height: 7px;
                top: 0;
                left: -25px;
                border-radius: 100%;
                border: 2px solid #ff5d5d;
            }
            #{$root}__icon {
                background-image: url(../images/icon-qna_call.svg);
            }
            #{$root}__date {
                text-align: left;
                text-indent: 24px;
            }
        }
    }
    &__text {
        vertical-align: top;
        width: calc(100% - 24px);
        height: 40px;
        margin-left: 3px;
        @include line-clamp(20px, 40px, 2);
        display: inline-block;
    }
    &__date {
        display: block;
        text-align: right;
        color: #9da3ac;
        font-size: 12px;
        margin-top: 4px;
    }
}