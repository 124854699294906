.category {
    $root: &;
    height: 40px;
    max-width: 100%;
    position: fixed;
    top: 50px;
    background: #fff;
    padding: 0 5px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    z-index: 99;
    transition: top 0.05s ease-in-out;
    padding-right: 50px;
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     //margin: 0 0 0 50%;
    //     border-left: 1px solid #f5f7fa;
    //     border-right: 1px solid #f5f7fa
    // }
    &--type2 {
        top: 100px;
        // @include desktop-width {
        //     margin: 0 0 0 50%;
        // }
    }
    &--type3 {
        position: relative;
        top: 0;
        // @include desktop-width {
        //     width: auto;
        // }
    }
    &--type-fa {
        padding-right: 0;
        // @include desktop-width {
        //     margin: 0 0 0 50%;
        // }
    }
    &__wrap {
        font-size: 0;
        height: 40px;
        &.pc {
            display: none;
        }
    }
    &__anchor {
        display: inline-block;
        padding: 6px 10px;
        color: #9da3ac;
        line-height: 28px;
        font-weight: 400;
        font-size: 16px;
        &.active {
            color: #333;
            font-weight: 500;
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 20px);
                height: 2px;
                background: #0da8ff;
            }
        }
    }
    &__button {
        position: absolute;
        top: 0;
        right: 5px;
        width: 15%;
        height: 40px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 25%);
        background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0), #fff 25%);
        &:before {
            position: absolute;
            content: "";
            width: 40px;
            height: 40px;
            background-image: url('../images/icon-view02.svg');
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            top: 50%;
            right: -5px;
            transform: translateY(-50%);
        }
    }
}

.category-layer {
    $root: &;
    display: none;
    position: absolute;
    width: 100%;
    min-height: 250px;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 100;
    &__content {
        position: relative;
        color: #9da3ac;
        @include flex(center,
        flex-start);
        flex-wrap: wrap;
        padding: 6px 0;
        .category__anchor {
            width: 33.33%;
            font-size: 16px;
            color: #9da3ac;
            text-align: center;
            padding: 6px 0;
            &.active {
                color: #333;
                font-weight: 500;
                &:after {
                    display: none;
                }
            }
        }
    }
    &__button {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        text-align: center;
        border-top: 1px solid #f5f7fa;
        color: #9da3ac;
        font-size: 16px;
        line-height: 40px;
    }
}

.category-slider {
    height: 100% !important;
    .slick-list,
    .slick-track {
        height: 100% !important;
    }
    .slick-next,
    .slick-prev {
        display: none !important;
    }
    &__content {
        height: 100%;
    }
    .tab__panel {
        padding-top: 0;
    }
}

.category2 {
    $root: &;
    width: 100%;
    max-width: 100%;
    height: 40px;
    background: #fff;
    padding-left: 5px;
    padding-right: 30px;
    border-bottom: 1px solid #efefef;
    z-index: 99;
    &__wrap {
        height: 40px;
        white-space: nowrap;
    }
    &__anchor {
        display: inline-block;
        padding: 10px;
        color: #949aa3;
        font-size: 14px;
        line-height: 20px;
        &.active {
            color: #05a4e8;
            font-weight: 500;
        }
    }
    &--submenu {
        position: fixed;
        top: 90px;
        border-bottom: none;
        transition: top 0.05s ease-in-out;
        &+.category-slider {
            .tab__panel {
                padding-top: 40px;
            }
        }
        // @include desktop-width {
        //     width: 50%;
        //     max-width: 525px;
        //     //margin: 0 0 0 50%;
        // }
    }
}