.text {
    text-align: left;
    font-weight: 400;
    &--small {
        font-size: 12px;
        line-height: 12px;
        color: #9da3ac;
    }
    &--lineheight18 {
        line-height: 18px;
    }
    &--lineheight26 {
        line-height: 26px;
    }
    &--middle {
        font-size: 16px;
    }
    &--large {
        font-weight: 500;
        line-height: 28px;
    }
    &--large17 {
        font-size: 17px;
        line-height: 26.5px;
    }
    &--large18 {
        font-size: 18px;
    }
    &--large19 {
        font-size: 19px;
    }
    &--large20 {
        font-size: 20px;
        color: #0da8ff;
    }
    &--large22 {
        font-size: 22px;
    }
    &--xlarge {
        font-size: 24px;
    }
    &--weight {
        font-weight: 500;
    }
    &--center {
        text-align: center;
    }
    &--color-fff {
        color: #fff;
    }
    &--color-333 {
        color: #333;
    }
    &--color-666 {
        color: #666;
    }
    &--color-gray {
        color: #9da3ac;
    }
    &--color-blue {
        color: #0da8ff;
    }
    &--color-green {
        color: #04d6cd;
    }
    &--color-orange {
        color: #f59600;
    }
}

.title {
    &+.sub-title {
        margin-top: 6px;
    }
}