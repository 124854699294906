@import 'mixin';
@import 'variable';
// Components SCSS
@import 'components/header';
@import 'components/footer';
@import 'components/button';
@import 'components/icon';
@import 'components/notice-bar';
@import 'components/form';
@import 'components/table';
@import 'components/title';
//@import 'components/dimed-alert';
@import 'components/accordion';
@import 'components/card';
@import 'components/quick-menu';
@import 'components/category';