.quick-menu {
    $root: &;
    position: fixed;
    //right: 16px;
    //bottom: 66px;
    right: 8px;
    bottom: 58px;
    z-index: 800;
    @include desktop-width {
        position: sticky;
        width: 50px;
        margin: 0 0 0 calc(50% + 475px);
        // width: 50%;
        // max-width: 525px;
        // margin: 0 0 0 50%;
        // left: 0;
        // display: flex;
        // flex-direction: column;
        // justify-content: right;
        // align-items: flex-end;
    }
    &__dim {
        display: none;
        position: fixed;
        width: 100%;
        height: calc(100% + 60px);
        background: rgba(0, 0, 0, 0.7);
        top: 0;
        left: 0;
        z-index: 1001;
        overflow: hidden;
    }
    .floating-menu {
        position: fixed;
        right: 8px;
        bottom: 108px;
        @include desktop-width {
            width: 50%;
            max-width: 525px;
            margin: 0 0 0 50%;
            left: 0;
        }
        &__list {
            position: relative;
            margin-bottom: -8px;
            @include desktop-width {
                text-align: right;
            }
            span {
                position: absolute;
                top: 30%;
                left: -100px;
                width: 90px;
                text-align: right;
                color: #fff;
                font-size: 12px;
                @include desktop-width {
                    left: auto;
                    right: 62px;
                }
            }
        }
        &__button {
            width: 52px;
            height: 52px;
            background-image: url(../images/icon-facebook.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &--kakao {
                background-image: url(../images/icon-kakao_story.png);
            }
            &--band {
                background-image: url(../images/icon-band.png);
            }
            &--blog {
                background-image: url(../images/icon-blog.png);
            }
            &--url {
                background-image: url(../images/icon-url.svg);
            }
            &--message {
                background-image: url(../images/icon-message.png);
            }
        }
    }
    &__button {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &--more {
            position: relative;
            display: block;
            background-image: url(../images/icon-share.svg);
            transition-duration: .2s;
            z-index: 1001;
            &.on {
                background-image: url(../images/icon-close02.svg);
            }
        }
        &--top {
            display: none;
            background-image: url(../images/icon-scroll_top.svg);
            //background-color: rgba(255, 255, 255, 0.8);
            //background-size: 15px 19px;
            //border: 1px solid #fff;
            //margin-top: 8px;
        }
        @include desktop-width {
            border-radius: 0;
        }
    }
}