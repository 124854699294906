.card {
    $root: &;
    position: relative;
    display: block;
    color: #333;
    span {
        display: block;
    }
    img {
        width: 100%;
    }
    &+& {
        margin-top: 12px;
    }
    &+.scrap__date {
        padding-top: 24px;
    }
    &__center {
        @include flex(start, center);
        flex-direction: column;
        min-height: 72px;
    }
    &__top {
        //height: 136px;
        background-size: cover;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
    }
    &__bottom {
        padding: 13px 16px;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
    }
    &__badge {
        position: absolute;
        top: 12px;
        font-size: 0;
    }
    &__text {
        &+& {
            margin-top: 6px;
        }
        &--type2 {
            color: #0da8ff;
            margin-left: 20px;
        }
        &+&--type2 {
            margin-top: 0;
        }
        &--type3 {
            margin-left: 20px;
        }
    }
    &__title {
        @include line-clamp(20px, 40px, 2);
        display: -webkit-box !important;
        &--type2 {
            font-size: 16px;
        }
        &+#{$root}__date {
            margin-top: 3px;
        }
    }
    &--finish {
        #{$root} {
            &__title {
                color: #9da3ac;
            }
            &__date {
                color: #9da3ac;
            }
        }
    }
    &--type2 {
        min-height: 96px;
        padding: 12px 16px;
        background-color: #fff;
        border-radius: 5px;
        #{$root} {
            &__top {
                height: auto;
                @include line-clamp(20px, 40px, 2);
                display: -webkit-box !important;
            }
            &__bottom {
                padding: 12px 0 0 0;
            }
        }
    }
    &--type3 {
        @include flex(start, space-between);
        padding: 14px;
        background-color: #fff;
        border-radius: 5px;
        #{$root} {
            &__left {
                width: calc(100% - 100px);
            }
            &__right {
                width: 88px;
                height: 88px;
                border-radius: 5px;
            }
            &__row {
                &:not(:first-of-type) {
                    margin-top: 9px;
                }
                &:first-of-type {
                    .text {
                        @include line-clamp(20px, 40px, 2);
                        display: -webkit-box !important;
                    }
                }
            }
        }
    }
    &--type4 {
        display: inline-block;
        padding: 0;
        width: 140px;
        color: #333;
        /* 
        &+& {
            margin-top: 0;
            margin-left: 12px;
        } */
        #{$root} {
            &__top {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
            &__bottom {
                background-color: transparent;
                padding: 10px 0 0 0;
                .text {
                    @include line-clamp(20px, 40px, 2);
                    display: block !important;
                }
            }
        }
    }
    &--big {
        //width: 154px;
        width: auto;
    }
    &--type5 {
        border-radius: 0;
        position: relative;
        overflow: hidden;
        #{$root} {
            &__bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 58px;
                padding: 10px 12px;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 0;
                .text {
                    @include line-clamp(20px, 40px, 2);
                    display: -webkit-box !important;
                }
            }
        }
        &.card {
            &--left {
                height: 100%;
                border-radius: 5px 0 0 5px;
            }
            &--right {
                height: calc(50% - 2px);
                border-radius: 0 5px 0 0;
                &:last-of-type {
                    border-radius: 0 0 5px 0;
                    margin-top: 4px;
                }
            }
        }
    }
    &--type6 {
        background-color: #fff;
        border-radius: 5px;
        margin-top: 12px;
        #{$root} {
            &__top {
                height: auto;
                padding: 10px 20px;
            }
            &__bottom {
                padding: 10px 20px;
                @include flex(center, space-between);
                border-top: 1px solid #eee;
            }
        }
    }
    &--type7 {
        background-color: #fff;
        border-radius: 5px;
        span {
            display: inline-block;
        }
        #{$root} {
            &__flex-box {
                @include flex(center, space-between);
                &--type2 {
                    padding: 10px 20px;
                    border-bottom: 1px solid #eee;
                }
                &--type3 {
                    padding: 14px 20px;
                    min-height: 120px;
                }
                &--type4 {
                    padding: 23px 20px 14px;
                }
                &--type5 {
                    border-bottom: 1px solid #eee;
                }
            }
            &__box {
                padding: 10px 0 10px 20px;
                &--type2 {
                    padding: 10px 20px 10px 0;
                }
            }
            &__column {
                //min-height: 92px;
                @include flex(start, space-between);
                flex-direction: column;
                &--type2 {
                    @include align-items(center);
                    flex-direction: row;
                }
                &--type3 {
                    min-height: 78px;
                }
            }
            &__row {
                padding: 10px 20px;
                min-height: 105px;
                &--type2 {
                    min-height: auto;
                    padding: 20px;
                    div {
                        &:not(:first-of-type) {
                            margin-top: 8px;
                        }
                    }
                }
            }
            &__bottom {
                padding: 16px 0 0 0;
                text-align: left;
            }
        }
    }
    &--type8 {
        background-color: #f5f7fa;
        padding: 0;
    }
    &-array {
        display: flex;
        &__column {
            width: calc(66.72% - 7px / 2);
            &:last-of-type {
                width: calc(33.28% - 7px / 2);
                margin-left: 7px;
            }
        }
        &+#{$root} {
            margin-top: 12px;
        }
    }
    &__relative {
        position: relative;
    }
}

*+.card--type7 {
    margin-top: 12px;
}