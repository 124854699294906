.button {
    $root: &;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    background-color: #0da8ff;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    vertical-align: middle;
    &:disabled {
        background-color: #ccc;
    }
    &--height40 {
        height: 40px;
        line-height: 38px;
    }
    &--height28 {
        height: 28px;
        line-height: 26px;
    }
    &--light {
        font-weight: 400;
    }
    &--border-blue {
        background-color: #fff;
        border: solid 1px #0da8ff;
        color: #0da8ff;
    }
    &--gray {
        background-color: #f5f7fa;
    }
    &--font16 {
        font-size: 16px;
    }
    &--font-gray {
        color: #ccc;
    }
    &--auto {
        height: auto;
        line-height: unset;
    }
    &--auto2 {
        line-height: unset;
    }
    &--icon {
        #{$root}__text {
            position: relative;
            padding-left: 22px;
            &::before {
                position: absolute;
                content: "";
                width: 16px;
                height: 16px;
                background-image: url(../images/icon-consulting.png);
                background-size: cover;
                background-repeat: no-repeat;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        &-big {
            #{$root}__text {
                padding-left: 30px;
                &::before {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    &--type2 {
        text-align: left;
        padding-left: 15.38%;
        #{$root}__text {
            &::before {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                background-image: url(../images/icon-law.png);
                background-size: cover;
                background-repeat: no-repeat;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    &--type3 {
        border-radius: 0;
        background-color: transparent;
        color: #0da8ff;
    }
    &--law {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-law.png);
            }
        }
    }
    &--tax {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-tax.png);
            }
        }
    }
    &--labor {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-labor.png);
            }
        }
    }
    &--property {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-property.png);
            }
        }
    }
    &--profile {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-profile.svg);
            }
        }
    }
    &--more {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-more_white.svg);
            }
        }
    }
    &--more-lightgray {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-more_lightgray.svg);
            }
        }
    }
    &--more-blue {
        #{$root}__text {
            &::before {
                background-image: url(../images/icon-more_blue.svg);
            }
        }
    }
    &--circle {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        font-size: 12px;
    }
}

.button-array {
    $root: &;
    @include flexbox();
    &__column {
        width: calc(50% - 4px);
        &:nth-child(2n) {
            margin-left: 8px;
        }
    }
    &--type2 {
        #{$root} {
            &__column {
                width: calc(50% - 6px);
                &:nth-child(2n) {
                    margin-left: 12px;
                }
            }
        }
    }
}

.button-wrap {
    &--bottom {
        position: fixed;
        bottom: 0;
        width: calc(100% - 30px);
        padding-bottom: 20px;
        background-color: #fff;
        z-index: 1;
        @include desktop-width {
            width: 50%;
            max-width: 380px;
            margin: 0 0 0 50%;
            left: 15px;
        }
    }
    &--type2 {
        background-color: #f5f7fa;
    }
}

.text-button {
    color: #9da3ac;
    margin: 0 auto;
    &--type2 {
        color: #f59600;
        height: 41px;
        padding: 0 20px;
    }
}

a {
    &.button {
        color: #fff;
    }
    &.button--border-blue {
        color: #0da8ff;
    }
    &.button--gray {
        color: #9da3ac;
    }
    &.text--color-fff {
        color: #fff;
    }
}

.content-button-wrap {
    margin-top: 10px;
}