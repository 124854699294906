.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(../images/icon-spot.svg);
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
    &__text {
        &--gray {
            color: #9da3ac;
        }
    }
    &--type2 {
        background-image: url(../images/icon-call.svg);
    }
    &--type3 {
        background-image: url(../images/icon-mail.svg);
    }
    &--type4 {
        background-image: url(../images/icon-awards.svg);
    }
    &--type5 {
        background-image: url(../images/icon-eye.svg);
    }
    &--type6 {
        background-image: url(../images/icon-like.svg);
    }
    &--type7 {
        background-image: url(../images/icon-career.svg);
    }
    &--accordion {
        width: 16px;
        height: 19px;
        padding-top: 10px;
        background-image: url(../images/icon-qna_question.svg);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 0 bottom;
        vertical-align: top;
        &-answer {
            background-image: url(../images/icon-qna_answer.svg);
        }
        &-answer-fa {
            background-image: url(../images/icon-qna_answer_fa.svg);
        }
        &-call {
            background-image: url(../images/icon-qna_call.svg);
        }
    }
    &--rectangle {
        width: 46px;
        height: 20px;
        background-image: none;
        color: #fff;
        font-size: 12px;
        text-align: center;
        &-orange {
            background-color: #f59600;
        }
        &-green {
            background-color: #04d6cd;
        }
        &-blue {
            background-color: #0da8ff;
        }
        &-gray {
            background-color: #9da3ac;
        }
    }
    &--check {
        width: 63px;
        height: 63px;
        background-image: url(../images/icon-check_02.svg);
        &+.text--large {
            margin-top: 12px;
        }
    }
    &--round {
        display: inline-block;
        width: auto;
        height: 28px;
        padding: 0 16px;
        line-height: 26px;
        border-radius: 5px;
        background-color: #0da8ff;
        background-image: none;
        text-align: center;
        color: #fff;
        &-gray {
            background-color: #dde4ed;
            color: #333;
        }
        &+& {
            margin-left: 3px;
        }
    }
}