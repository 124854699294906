// device size
$desktop          	    : 1080px;
$tablet     		    : 1079px;
$mobile     		    : 749px;

// font
$font-default           : #666;

// color
$primary-color          : #0066cc;
$brand-color            : #ff42ad;
$point-color            : #781827;

