.notice-bar {
    height: 43px;
    padding-left: 47px;
    @include flex(center, unset);
    background-color: #f5f7fa;
    color: #0da8ff;
    background-image: url(../images/icon-info.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 15px center;
}