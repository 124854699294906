.header {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    padding: 13px 15px;
    //transition: background-color 0.5s;
    &.event {
        background-color: rgba(255, 255, 255, 0.9);
        border-bottom: 1px solid #fff;
    }
    &__align {
        @include flex(center, space-between);
        flex-wrap: nowrap;
    }
    &__logo {
        img {
            width: 76px;
            height: 17px;
        }
    }
    &__button {
        button {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin-left: 10px;
        }
        &--search {
            background-image: url(../images/icon-search.svg);
        }
        &--mypage {
            background-image: url(../images/icon-mypage.svg);
            margin-left: 12px;
        }
        &--close {
            background-image: url(../images/icon-close.svg);
        }
        &--type2 {
            position: absolute;
            right: 20px;
            @include vertical-center();
        }
    }
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     margin: 0 0 0 50%;
    //     border-left: 1px solid #f5f7fa;
    //     border-right: 1px solid #f5f7fa
    // }
}

.nav-up {
    top: -130px !important;
    //transition: top 0.1s ease-in-out;
}

.header-title {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 50px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    background: #fff;
    transition: top 0.05s ease-in-out;
    &--type2 {
        top: 0;
        border: none;
    }
    &__button {
        width: 45px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-position: center;
        background-image: url(../images/icon-back.svg);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 101;
        &--close {
            background-image: url(../images/icon-close.svg);
            left: initial;
            right: 0;
        }
    }
    &__text {
        display: table;
        width: 100%;
        height: 50px;
        text-align: center;
        span {
            display: table-cell;
            width: 100%;
            vertical-align: middle;
            padding: 0 50px;
            font-size: 16px;
            line-height: 1.25;
        }
    }
    &+.category {
        border-top: none;
    }
    @include desktop-width {
        width: 50%;
        max-width: 525px;
        margin: 0 0 0 50%;
        border-left: 1px solid #f5f7fa;
        border-right: 1px solid #f5f7fa;
    }
}

.header-layer {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: rgb(158, 154, 154);
}

.header-button {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 50px;
    button {
        width: 45px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 101;
        background-image: url(../images/icon-close.svg);
    }
    &--type2 {
        button {
            width: 60px;
            height: 50px;
            background-image: url(../images/icon-close03.svg);
            background-size: 17px 17px;
        }
    }
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     margin: 0 0 0 50%;
    // }
}

.search {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 350px;
    background: #fff;
    z-index: 1001;
    $root: &;
    &__top {
        height: 50px;
        padding: 13px 20px;
        position: relative;
    }
    &__input {
        border-bottom: 1px solid #333;
        width: calc(100% - 80px);
        height: 24px;
        padding-bottom: 5px;
        padding-right: 20px;
        position: relative;
        input {
            width: 100%;
            &::placeholder {
                color: #999;
            }
        }
        .results-clear {
            position: absolute;
            bottom: 5px;
            right: 0;
            width: 16px;
            height: 16px;
            background: url(../images/icon-clear.svg)no-repeat center;
            background-size: contain;
            &.hide {
                display: none;
            }
        }
    }
    &__bottom {
        padding: 20px;
        height: 100%;
        font-size: 0;
        .text {
            margin-bottom: 2px;
        }
    }
    &--type2 {
        display: block;
        position: unset;
        min-height: auto;
        background-color: transparent;
        #{$root} {
            &__input {
                width: calc(100% - 34px);
                input {
                    width: 100%;
                    &::placeholder {
                        color: #ccc;
                    }
                }
            }
        }
    }
}

.search-bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    z-index: 1000;
}