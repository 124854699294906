.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: #fff;
    z-index: 1000;
    border-top: 1px solid #efefef;
    &.pc {
        z-index: 700;
    }
    // @include desktop-width {
    //     width: 50%;
    //     max-width: 525px;
    //     margin: 0 0 0 50%;
    //     border-left: 1px solid #f5f7fa;
    //     border-right: 1px solid #f5f7fa;
    // }
    &__menu {
        @include flexbox();
        @include align-items(center);
    }
    &__gnb {
        flex: 1;
        button {
            background-repeat: no-repeat;
            background-position: center top 6px;
            background-size: 24px 24px;
            width: 100%;
            height: 50px;
            padding-top: 27px;
            font-size: 10px;
            color: #333;
        }
        &--home {
            background-image: url(../images/icon-home.svg);
        }
        &--contents {
            background-image: url(../images/icon-contents.svg);
        }
        &--talk {
            background-image: url(../images/icon-talk.svg);
        }
        &--event {
            background-image: url(../images/icon-event.svg);
        }
        &--view {
            background-image: url(../images/icon-view.svg);
        }
    }
}

.panel {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: -90%;
    width: 85%;
    background: #fff;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    z-index: 1001;
    transition: margin-right .5s;
    @include flex(start, space-between);
    flex-direction: column;
    overflow-y: scroll;
    &__content {
        position: relative;
        width: 100%;
        z-index: 1001;
    }
    &__button {
        position: absolute;
        right: 5px;
        width: 47px;
        height: 47px;
        background: url(../images/icon-close.svg) no-repeat center /24px 24px;
    }
    &__top {
        padding: 47px 15px 16px;
        &--interval {
            margin: 8px 0 12px 0;
        }
        .label-location {
            color: #999;
            position: relative;
            padding-left: 18px;
            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url(../images/icon-spot02.png) no-repeat center;
                background-size: cover;
            }
        }
    }
    .menu {
        @include clearfix();
        &__field {
            background: #f5f7fa;
            padding: 8px 15px;
            position: relative;
            padding-left: 50px;
            &--fa {
                padding-left: 20px;
                &:after {
                    display: none;
                }
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
                width: 22px;
                height: 22px;
                background-image: url(../images/icon-menu01.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &--type2 {
                &:after {
                    background-image: url(../images/icon-menu02.svg);
                }
            }
            &--type3 {
                &::after {
                    background-image: url(../images/icon-menu03.svg);
                }
            }
        }
        &__list {
            padding: 8px 15px;
            float: left;
            width: 50%;
            background: #fff;
            border-bottom: 1px solid #f5f7fa;
            border-right: 1px solid #f5f7fa;
            &:nth-of-type(2n) {
                border-right: none;
            }
            a {
                display: block;
                color: #333;
            }
            &--fa {
                float: initial;
                width: 100%;
                padding-left: 35px;
            }
        }
    }
    @include desktop-width {
        width: 42.5%;
        max-width: 420px;
    }
    .site {
        width: 100%;
        @include flex(center, space-between);
        border-top: 1px solid #dde4ed;
        margin-top: 40px;
        li {
            position: relative;
            width: 30%;
            &:not(:first-of-type) {
                &::after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 18px;
                    background-color: #dde4ed;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &:nth-of-type(2) {
                width: 40%;
            }
            a {
                display: block;
                width: 100%;
                padding: 12px 0;
                font-size: 12px;
                color: #949aa3;
                text-align: center;
            }
        }
    }
}

.panel-background {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1001;
}

.panel-active {
    margin-right: 0;
    transition: margin-right .5s;
}