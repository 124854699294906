.form {
    $root: &;
    padding-top: 18px;
    &--type2 {
        padding: 0;
    }
    &__kinds {
        font-size: 12px;
        color: #9da3ac;
        &+#{$root}__text-area {
            margin-top: 7px;
        }
        &+#{$root}__radio {
            margin-top: 8px;
        }
        &--type2 {
            color: #fff;
        }
    }
    &__wrap {
        position: relative;
    }
    &__row {
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }
    &__input-text {
        width: 100%;
        padding: 6px 0;
        border-bottom: 1px solid #000;
        &::placeholder {
            color: #ccc;
        }
        &--type2 {
            border-bottom: 1px solid #fff;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
    }
    &__packet {
        &+& {
            margin-top: 28px;
        }
        &+&--type3 {
            margin-top: 4px;
        }
        &+&--type4 {
            margin-top: 12px;
        }
        &+&--type5 {
            margin-top: 54px;
        }
        &--type2 {
            @include flex(center, space-between);
            &+& {
                margin-top: 5px;
            }
        }
        &--type3 {
            text-align: center;
        }
        &--type6 {
            margin: 0 -20px;
            background-color: #f5f7fa;
            padding: 20px;
            &+& {
                margin-top: 0;
                padding-top: 0;
            }
        }
        &--type7 {
            @include flex(center, start)
        }
    }
    &__column {
        margin-left: 16px;
    }
    &__select {
        width: 100%;
        padding-bottom: 6px;
        background-image: url(../images/icon-select.svg);
        background-size: 24px 24px;
        background-position: right center;
        background-repeat: no-repeat;
        border-bottom: 1px solid #000;
        @include css3-prefix(appearance, none);
        font-size: 14px;
        color: #333;
        &::-ms-expand {
            display: none;
        }
        &--type2 {
            width: 105px;
        }
    }
    &__checkbox {
        $check: &;
        position: relative;
        input[type="checkbox"] {
            display: none;
            &+label {
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 24px;
                background-image: url(../images/icon-check.svg);
                background-size: 18px 18px;
                background-position: left center;
                background-repeat: no-repeat;
            }
            &:checked {
                &+label {
                    background-image: url(../images/icon-check-active.svg);
                }
            }
        }
        &-text {
            font-size: 12px;
            font-weight: 500;
            margin-left: 24px;
        }
        &--type2 {
            #{$check} {
                &-text {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        &--type3 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            input[type="checkbox"] {
                &+label {
                    position: relative;
                    width: 100%;
                    height: 40px;
                    display: block;
                    background-position: right 18px center;
                }
            }
        }
        &--type4 {
            input[type="checkbox"] {
                &+label {
                    background-image: url(../images/icon-check_03.svg);
                }
                &:checked {
                    &+label {
                        background-image: url(../images/icon-check_03-active.svg);
                    }
                }
            }
            #{$check} {
                &-text {
                    font-size: 14px;
                    font-weight: 400;
                    color: #fff;
                    vertical-align: middle;
                }
            }
        }
        &--type5 {
            #{$check} {
                &-text {
                    font-size: 16px;
                    color: #ccc;
                    font-weight: 400;
                }
            }
            input[type="checkbox"] {
                &+label {
                    width: calc(100% - 100px);
                }
                &:checked {
                    &+label {
                        &+span {
                            color: #0da8ff;
                        }
                    }
                }
            }
        }
        &--type6 {
            #{$check} {
                &-text {
                    font-size: 14px;
                    color: #ccc;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
            input[type="checkbox"] {
                &+label {
                    padding-left: 0;
                    width: 80px;
                    background-image: none;
                }
                &:checked {
                    &+label {
                        background-image: none;
                        &+span {
                            color: #0da8ff;
                        }
                    }
                }
            }
        }
    }
    &__anchor {
        display: block;
        border-bottom: 1px solid #999;
        color: #999;
        font-size: 12px;
    }
    &__text-area {
        width: 100%;
        min-height: 240px;
        padding: 12px 15px;
        border-radius: 5px;
        border: solid 1px #d5e0ef;
        background-color: #f5f7fa;
        &::placeholder {
            color: #9da3ac;
        }
    }
    &__add-button {
        padding: 8px 0 8px 24px;
        background-image: url(../images/icon-more.svg);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 0 center;
        text-align: center;
    }
    &__add-file {
        position: relative;
        padding: 9px 10px;
        border-radius: 5px;
        background-color: #f5f7fa;
        text-align: left;
        font-size: 12px;
        &+& {
            margin-top: 8px;
        }
    }
    &__clear-button {
        display: inline-block;
        position: absolute;
        width: 26px;
        height: 26px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(../images/icon-clear.svg);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        &.hide {
            display: none;
        }
    }
    &__radio {
        position: relative;
        display: inline-block;
        width: calc(50% - 6px);
        height: 40px;
        &:last-of-type {
            margin-left: 8px;
        }
        input[type="radio"] {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 5px;
            border: solid 1px #0da8ff;
            &:checked {
                background-color: #0da8ff;
                border: solid 1px transparent;
                &+span {
                    color: #fff;
                }
            }
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #0da8ff;
        }
        &--type2 {
            width: calc(100% / 3 - 10px * 2 / 3);
            &:not(:nth-of-type(3n+1)) {
                margin-left: 6px;
            }
            &:nth-of-type(n+4) {
                margin-top: 6px;
            }
            input[type="radio"] {
                border: solid 1px #0da8ff;
                &:checked {
                    background-color: #0da8ff;
                    &+span {
                        color: #fff;
                    }
                }
                &:disabled {
                    border: solid 1px #cccccc;
                    &+span {
                        color: #ccc;
                    }
                }
            }
            span {
                color: #0da8ff;
            }
        }
    }
    &__flex-box {
        @include flex(flex-end, space-between);
        margin-top: 8px;
    }
    &__date-radio {
        position: relative;
        display: inline-block;
        width: calc(100% / 6 - 20px * 5 / 6);
        height: 40px;
        input[type="radio"] {
            width: 100%;
            height: 100%;
            &:checked {
                &+#{$root}__date-text {
                    color: #0da8ff;
                    &+#{$root}__date-text {
                        color: #0da8ff;
                    }
                }
            }
            &:disabled {
                &+#{$root}__date-text {
                    color: #ccc;
                    &+#{$root}__date-text {
                        color: #ccc;
                    }
                }
            }
        }
        #{$root}__date-text {
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;
            &--large {
                bottom: auto;
                top: 0;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
}

.error {
    font-size: 12px;
    color: #ff5d5d;
    margin-left: 8px;
}

.input-text {
    width: calc(100% - 31px);
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    vertical-align: middle;
    &__button {
        display: inline-block;
        width: 26px;
        height: 30px;
        background-image: url(../images/icon-more_blue.svg);
        background-size: 16px 16px;
        background-position: right center;
        background-repeat: no-repeat;
        vertical-align: middle;
        &--type2 {
            background-image: url(../images/icon-delete.png);
        }
    }
    &::placeholder {
        color: #cccccc;
    }
}