.table {
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: center;
    margin-top: 47px;
    $root: &;
    tr {
        border-top: 1px solid #f2f5f9;
        &:first-of-type {
            border-top: none;
        }
    }
    th {
        padding: 10px 13px;
        background-color: #f5f7fa;
    }
    td {
        padding: 10px 0 10px 13px;
        &.left {
            display: table-cell;
            text-align: left;
        }
    }
    &--type2 {
        border-top: 1px solid #333;
        border-bottom: 1px solid #333;
        margin-top: 12px;
        text-align: left;
        tr {
            border-top: none;
            &:first-of-type {
                th {
                    padding: 16px 0 4px 0;
                }
                td {
                    padding: 16px 0 4px 13px;
                }
            }
            &:last-of-type {
                th {
                    padding: 4px 0 16px 0;
                }
                td {
                    padding: 4px 0 16px 13px;
                }
            }
        }
        th {
            padding: 4px 0;
            background-color: #fff;
            color: #9da3ac;
        }
        td {
            padding: 4px 0 4px 13px;
        }
    }
    &--type3 {
        border: none;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        padding: 0 20px;
        border-collapse: unset;
        margin-top: 12px;
        tr {
            border: none;
            &:last-of-type {
                td {
                    padding: 9px 0 18px;
                }
            }
        }
        th {
            padding: 9px 0;
            background-color: #fff;
            font-size: 12px;
            color: #9da3ac;
            text-align: center;
        }
        td {
            padding: 9px 0;
            text-align: center;
            border-top: 1px solid #eee;
        }
    }
    &--type4 {
        margin-top: 0;
        border: none;
        td {
            padding: 16px 0;
        }
        #{$root} {
            &__data {
                padding-left: 20px;
                &--type2 {
                    padding-right: 20px;
                }
            }
            &__highlight {
                background-color: #e9fffe;
                td {
                    &:first-of-type {
                        color: #04d6cd;
                    }
                }
            }
        }
    }
    &--type5 {
        border-top: none;
        border-bottom: 1px solid #9da3ac;
        margin-top: 0;
        thead {
            th {
                font-size: 12px;
                color: #fff;
                background-color: #9da3ac;
            }
        }
        tr {
            &:not(:first-of-type) {
                border-top: 1px solid #fff;
            }
        }
    }
    &__line-clamp {
        line-height: 20px;
        max-height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
    }
}